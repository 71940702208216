import {Grid} from "@material-ui/core"
import React from "react"
import {ScrapedGameData} from "../../../api/scrapApi"
import {GameCard} from "../../../components/GameCard"

export const GamesLine = (props: { games: ScrapedGameData[], currentLang: string }) => {
  const cards = props.games.slice(0, 4).map((game) => {
    return (
      <Grid item xs={12} sm={4} md={3}>
        <GameCard game={game} currentLang={props.currentLang}/>
      </Grid>
    )
  })
  return (
    <Grid container spacing={4}>
      {cards}
    </Grid>
  )
}