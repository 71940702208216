import {Box} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {Layout} from "../../components/Layout"
import {SectionContainer, SectionTitle} from "../../components/Shared"
import {useI18next} from "gatsby-plugin-react-i18next"
import * as scrapApi from "../../api/scrapApi"
import {ScrapedGameData} from "../../api/scrapApi"
import {GamesLine} from "./components/GameLine"
import {Jumbo} from "./components/Jumbo"

export const CatalogPage = () => {
  const [mostPlayed, setMostPlayed] = useState<ScrapedGameData[]>([])
  const [last, setlast] = useState<ScrapedGameData[]>([])
  const i18n = useI18next()
  useEffect(() => {
    scrapApi.fetchAllGames().then(
      (result) => {
        setMostPlayed(result.hits.hits.map((_) => _._source))
        setlast(result.hits.hits.map((_) => _._source))
      },
      (error) => {
      }
    )
  }, [])
  return (
    <Layout title={"Recalbox Plus"}>
      {mostPlayed[0] && (
        <Jumbo currentLang={i18n.language} game={mostPlayed[0]}/>
      )}
      <SectionContainer>
        <SectionTitle>Most played games today</SectionTitle>
        <GamesLine games={mostPlayed} currentLang={i18n.language}/>
      </SectionContainer>
      <SectionContainer>
        <SectionTitle>Not finished yet...</SectionTitle>
        <GamesLine games={last} currentLang={i18n.language}/>
      </SectionContainer>
      <Box marginBottom={10}/>
    </Layout>
  )
}
