import styled from "@emotion/styled"
import {Button, Typography} from "@material-ui/core"
import {getLogoUrl, getMediaUrl, getSynopsys, ScrapedGameData} from "../../../api/scrapApi"
import React from "react"
import {IoInformationCircleOutline} from "@react-icons/all-files/io5/IoInformationCircleOutline"
import {IoLogoGameControllerA} from "@react-icons/all-files/io/IoLogoGameControllerA"

const JumboInfo = styled.div(({theme}) => ({
  position: "absolute",
  bottom: "0",
  left: "0",
  width: "100%",
  padding: theme.spacings.md
}))

const JumboDescription = styled(Typography)(({theme}) => ({
  fontSize: "1.2rem",
  fontWeight: 700,
  position: "relative",
  width: "40%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
  textShadow: "1px 1px black"
}))

const JumboLogo = styled.img({
  width: "30%"
})

const JumboSystem = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: 700,
  textShadow: "1px 1px black",
})

const JumboContainer = styled.div({
  position: "relative"
})


const JumboVideo = styled.video({
  position: "relative",
  width: "100%",
  objectFit: "cover",
  height: "60vh"
})

const JumboCTAs = styled.div({})
const CTAButton = styled(Button)(({theme}) => ({
  marginRight: theme.spacings.sm,
}))

export const Jumbo = (props: { game: ScrapedGameData, currentLang: string }) => {
  const {game, currentLang} = props
  return (
    <JumboContainer>
      <JumboVideo autoPlay muted>
        <source src={getMediaUrl(game, "video-normalized")} type="video/mp4"/>
      </JumboVideo>
      <JumboInfo>
        <JumboSystem>{game.systeme.text}</JumboSystem>
        <JumboLogo src={getLogoUrl(game)}/>
        <JumboDescription>{getSynopsys(game, currentLang)}</JumboDescription>
        <JumboCTAs>
          <CTAButton
            variant="outlined"
            color="default"
            startIcon={<IoLogoGameControllerA/>}
          >
            Play
          </CTAButton>
          <CTAButton
            variant="outlined"
            color="default"
            startIcon={<IoInformationCircleOutline/>}
          >
            Info
          </CTAButton>
        </JumboCTAs>
      </JumboInfo>
    </JumboContainer>
  )
}
